<template>
    <div class="container" style="height:100%">

        <div class="row flex-grow-0 mb-4">
            <div class="col col-md-2">
                <div class="col" style="height:100%; width:100%">
                    <!-- <icon-select :current="detailInfo.icon" @on-change="onChangeIcon" /> -->
                    <!-- <button @click="toggleIconSelect">{{ isIconSelectOpen ? '닫기' : '아이콘 선택' }} </button> -->
                    <span class="control-icon" @click="toggleIconSelect" :disabled="!isEditMode">
                        <i :class="detailInfo.icon" style="font-size: 1rem; margin-right: 10px"></i>
                    </span>
                    <icon-select v-if="isIconSelectOpen" :current="detailInfo.icon" @on-change="onChangeIcon" />

                    <!-- <button class="control-icon" @click="toggleIconSelect" :disabled="!isEditMode"
                        style="border: none; background: none; padding: 0;">
                        <i :class="detailInfo.icon" style="font-size: 1.5rem; margin-right: 10px"></i>
                    </button> -->
                </div>
            </div>
            <div class="col col-md-3">
                <label class="col" for="sysType">
                    <span>{{ $t('계통유형코드') }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <input v-model="detailInfo.sysType" type="text" class="form-control" id="sysType"
                        :disabled="!isNewMode">
                </div>
            </div>
            <div class="col col-md-3">
                <div class="col">
                    <label class="col" for="sysTypeName">
                        <span>{{ $t('계통유형명') }}</span>
                        <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                    </label>
                    <div class="col">
                        <input v-model="detailInfo.sysTypeName" type="text" class="form-control" id="sysTypeName"
                            :disabled="!isEditMode">
                    </div>
                </div>
            </div>
            <div class="col col-md-2">
                <div class="col">
                    <label class="col" for="sysTypeEnabled">사용여부</label>
                    <div class="col">
                        <select v-model="detailInfo.enabled" class="form-control" id="sysTypeEnabled"
                            :disabled="!isEditMode">
                            <option value="">{{ $t('사용여부선택') }}</option>
                            <option v-for="code in $store.state.commonCodes.enabled" :key="code.value"
                                :value="code.value">
                                {{
                                code.text }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col col-md-2">
                <div class="col">
                    <label class="col" for="sysTypeEnabled">집계사용여부</label>
                    <div class="col">
                        <select v-model="detailInfo.sumAvail" class="form-control" id="sysTypeEnabled"
                            :disabled="!isEditMode">
                            <option value="">{{ $t('사용여부선택') }}</option>
                            <option v-for="code in $store.state.commonCodes.enabled" :key="code.value"
                                :value="code.value">
                                {{
                                code.text }}</option>
                        </select>
                    </div>
                </div>
            </div>

        </div>

        <div class="row flex-grow-0 mb-2">
            <div class="col-md-1" style="text-align: center;">
                <label for="equipDesc">설명</label>
            </div>
            <div class="col-md-11">
                <textarea v-model="detailInfo.description" type="text" class="form-control" id="equipDesc"
                    :disabled="!isEditMode" style="resize: none; overflow-y: auto;" rows="3"></textarea>
            </div>
        </div>

        <div class="row flex-grow-1 mb-2">
            <TableListComponent ref="TableListComponent" :title="''" :columns="columns"
                :rows="detailInfo.allowedEquipTypes" :keyField="'equipType'" :mode="mode" :useMaxHeight="true"
                :useFilter="false" :controls="controls" :transCodes="transCodes" @button-click="handleListButtonClick"
                @row-click="handleListItemClick" />

            <b-modal ref="AllowedEqpTypeDetailModal" size="md" centered @hidden="closeCodeItemModal">
                <template #modal-header>
                    <h5>{{ tempAllowedEqpTypeIdx !== null || undefined ? '설비 유형 편집' : '설비 유형 추가' }}</h5>
                </template>
                <template #modal-footer centered>
                    <button type="button" class="btn btn-primary" @click="handleAddListItem"
                        style="flex: 1;">저장</button>
                    <button type="button" class="btn btn-secondary" @click="closeCodeItemModal"
                        style="flex: 1;">닫기</button>
                </template>
                <AllowedEqpTypeDetailPanel ref="AllowedEqpTypeDetailPanel" :mode="mode" />
            </b-modal>
        </div>

    </div>
</template>

<script>
// import backEndApi from "@src/api/backEndApi";
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
import AllowedEqpTypeDetailPanel from "./AllowedEqpTypeDetailPanel.vue";


export default {
    components: {
        TableListComponent,
        AllowedEqpTypeDetailPanel,
    },
    props: ['detailInfo', 'mode'],
    data() {
        return {
            isIconSelectOpen: false,
            controls: [
                { event: 'delete', label: '-', class: 'btn-danger' },
                { event: 'regist', label: '+', class: 'btn-warning' },
            ],
            transCodes: [
                {
                    field: 'reltnType',
                    codes: this.$store.state.commonCodes.equipReltnType,
                },
            ],
            columns: [ // Detail 테이블 column
                {
                    label: this.$t("설비유형명"),
                    field: "equipTypeName",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t("설비유형"),
                    field: "equipType",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t("관계유형"),
                    field: "reltnType",
                    type: "text",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
            ],
            tempAllowedEqpType: null,
            tempAllowedEqpTypeIdx: null,
            equipTypeList: null,
        }
    },
    async created() {
    },
    async mounted() {
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
        isNewMode() {
            return this.mode === 'new';
        }
    },
    watch: {
        detailInfo() {
        },
    },
    methods: {
        toggleIconSelect() {
            if (this.isEditMode) {
                this.isIconSelectOpen = !this.isIconSelectOpen;
            }
        },
        onChangeIcon(value) {
            this.toggleIconSelect();
            if (!this.isEmpty(value)) this.detailInfo.icon = value.icon;
        },
        async handleListButtonClick(event, options) {
            const that = this;


            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                case 'delete':
                    await that.deleteChecked();
                    break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            const that = this;
            if (toggle || check || !this.isEditMode) return;
            that.tempAllowedEqpTypeIdx = index;
            const findAllow = this.detailInfo.allowedEquipTypes.find(item => item.equipType == row.equipType);
            // that.tempAllowedEqpType = {...row};
            await that.$refs.AllowedEqpTypeDetailModal.show();
            await that.$refs.AllowedEqpTypeDetailPanel.setData(findAllow);
        },
        closeCodeItemModal() {
            const that = this;
            if (that.tempAllowedEqpTypeIdx !== null || undefined) that.tempAllowedEqpTypeIdx = null;
            that.$refs.AllowedEqpTypeDetailModal.hide()
        },
        async deleteChecked() {
            const that = this;

            try {
                const checked = this.$refs['TableListComponent'].getCheckedRows();

                if (!that.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        // detailInfo에서 checked 항목을 제외.
                        that.detailInfo.allowedEquipTypes = that.detailInfo.allowedEquipTypes.filter(item => {
                            return !checked.find(checkedItem => checkedItem.equipType === item.equipType);
                        })
                    });
                } else {
                    this.alertNoti("목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },
        async registNew() {
            const that = this;
            that.tempAllowedEqpType = null;

            that.$refs.AllowedEqpTypeDetailModal.show();
        },
        handleAddListItem() {
            const equipType = this.$refs.AllowedEqpTypeDetailPanel.getData();

            if (equipType) {
                if (this.tempAllowedEqpTypeIdx !== null || undefined) {
                    this.$set(this.detailInfo.allowedEquipTypes, this.tempAllowedEqpTypeIdx, equipType);
                    this.tempAllowedEqpTypeIdx = null;
                } else {
                    let check = this.detailInfo.allowedEquipTypes.find(item => item.equipType === equipType.equipType);
                    if (check) {
                        // TODO: Alert Message
                        console.error('TODO: Alert Message');
                        console.error(`In Property is already exists in allowedEquipTypes. equipType:${equipType.equipType}, codeName:${equipType.codeName}`);
                    } else {
                        this.$set(this.detailInfo.allowedEquipTypes, this.detailInfo.allowedEquipTypes.length, equipType);
                    }
                }
            }


            this.$refs.AllowedEqpTypeDetailModal.hide();
        },
    }

}
</script>

<style scoped>

.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}

.control-icon {
    /* font-size: 68px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #455A64;
    background-color: #ECEFF1;
    transition: background-color 0.5s;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border-left: solid 1px #CFD8DC;
    border-top: solid 1px #CFD8DC;
    border-right: solid 1px #90A4AE;
    border-bottom: solid 1px #90A4AE;
}

.control-icon:hover {
    color: #ECEFF1;
    background-color: #455A64;
}
</style>